import React, { useState, useEffect, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

import * as THREE from "three";
import flamingoModel from "../models/Flamingo.glb";

const Flamingo = ({ rotation, setRotation, lightTarget }) => {
  const xPosition = (window.innerWidth / 2) - 775;
  const { scene, animations } = useGLTF(flamingoModel);
  const [mixer, setMixer] = useState(null);
  const [scale, setScale] = useState(0.2);
  const [position, setPosition] = useState([xPosition, 1, 0]);
  const [currentPathIndex, setCurrentPathIndex] = useState(0);

  const flightPaths = [
    { dx: -0.3, dy: 0.02, limitX: -xPosition, resetPosition: [xPosition, 1, 0] },
    { dx: 0.3, dy: -0.02, limitX: xPosition, resetPosition: [-xPosition, 1, 100] },
  ];

  useEffect(() => {
    if (animations && animations.length) {
      const action = new THREE.AnimationMixer(scene);
      const clipAction = action.clipAction(animations[0]);
      clipAction.setEffectiveTimeScale(2);
      clipAction.play();
      setMixer(action);
    }

    scene.traverse((object) => {
      if (object.isMesh) {
        object.castShadow = true;
        object.receiveShadow = true;
      }
    });
  }, [animations, scene]);

  useFrame(() => {
    if (mixer) mixer.update(0.01);

    setPosition((prevPosition) => {
      const { dx, dy, limitX } = flightPaths[currentPathIndex];
      const newX = prevPosition[0] + dx;
      const newY = prevPosition[1] + dy;

      if (dx < 0) {
        setRotation([0, -Math.PI / 4, 0]); // Left direction
      } else {
        setRotation([0, Math.PI / 4, 0]); // Right direction
      }

      if ((dx < 0 && newX <= limitX) || (dx > 0 && newX >= limitX)) {
        setScale(0.2);
        const nextIndex = (currentPathIndex + 1) % flightPaths.length;
        setCurrentPathIndex(nextIndex);
        return flightPaths[nextIndex].resetPosition;
      }

      return [newX, newY, prevPosition[2]];
    });

    setScale((prevScale) => {
      if (position[0] < 0.1 && position[0] > -0.1) {
        return Math.max(prevScale - 0.0002, 0.2);
      }
      return Math.min(prevScale + 0.0002, 0.5);
    });

    // Update the light target position to follow the flamingo
    if (lightTarget.current) {
      lightTarget.current.position.set(position[0], position[1], position[2]);
    }
  });

  return (
    <primitive
      object={scene}
      scale={scale}
      position={position}
      rotation={rotation}
      castShadow
      receiveShadow
    />
  );
};

const Scene = () => {
  const [rotation, setRotation] = useState([0, -Math.PI / 4, 0]);
  const lightTarget = useRef();

  return (
    <Canvas
      shadows
      camera={{ position: [-20, 0, 300], fov: 30 }}
      style={{ height: "100vh", width: "100vw" }}
    >
      <hemisphereLight
        intensity={1.5}
        color={new THREE.Color(0x0077ff)}
        groundColor={new THREE.Color(0xffffff)}
				position={[0, 50, 0]}
				target={lightTarget.current}
      />
      <directionalLight
        intensity={1.5}
        color={new THREE.Color(0xffffff)}
        position={[-100, 300, 100]}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-left={-2000}
        shadow-camera-right={2000}
        shadow-camera-top={2000}
        shadow-camera-bottom={-2000}
        shadow-camera-far={500}
        shadow-bias={-0.0005}
        target={lightTarget.current} // Attach the target to follow flamingo
      />
      {/* Target object for the directional light */}
      <mesh ref={lightTarget} position={[0, 0, 0]} />
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -60, 0]} receiveShadow>
        <planeGeometry args={[1000, 1000]} />
        <shadowMaterial opacity={0.5} />
      </mesh>
      <Flamingo rotation={rotation} setRotation={setRotation} lightTarget={lightTarget} />
    </Canvas>
  );
};

export default Scene;

import React from "react";
import FlamingoScene from "./Flamingo";
import { WHO_AMI_I, WHO_AMI_I_SUB, NAME, WHAT_DO_I_DO } from "../constants/home";
import "../styles/home.css";

const Home = () => {
  return (
    <div>
      <main>
        <section className="hero">
          <div className="content-wrapper">
            <div className="main-text">
              <h1 className="name">
                <span>{NAME}</span>
                <br />
                <span className="tagline">{WHAT_DO_I_DO}</span>
              </h1>
              <p className="description">
                {WHO_AMI_I}
                <br />
                <br />
                <span dangerouslySetInnerHTML={{
                  __html: WHO_AMI_I_SUB,
                }} />
              </p>
              <a href="http://nithin.cc/" className="btn">
                Know me
              </a>
            </div>
          </div>
        </section>
        <div className="flamingo-scene">
          <FlamingoScene />
        </div>
      </main>
    </div>
  );
};

export default Home;
